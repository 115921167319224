import { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Loading from "./Pages/Loading/Loading";
import { _apiGetCalls } from "./Services/ApiManager";

// Lazy load components
const AdminHome = lazy(() => import("./Pages/Admin/Home/Home"));
const Dashboard = lazy(() => import("./Pages/Admin/dashboard/Dashboard"));
const Blogs = lazy(() => import("./Pages/Admin/dashboard/Blogs"));
const BlogInsertion = lazy(() =>
  import("./Pages/Admin/dashboard/BlogInsertion")
);
const BlogEdit = lazy(() => import("./Pages/Admin/dashboard/BlogEdit"));
const Home = lazy(() => import("./Pages/Home/Home"));
const NewsDetail = lazy(() => import("./Pages/NewsDetail/NewsDetail"));
const NewsByCate = lazy(() => import("./Pages/NewsByCate/NewsByCate"));
const PrivacyPolicy = lazy(() => import("./Pages/Privacy/PrivacyPolicy"));
const Aboutus = lazy(() => import("./Pages/Aboutus/Aboutus"));

function App() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const fetchData = async () => {
    try {
      dispatch({ type: "loading", payload: true });

      const result = await _apiGetCalls("/Blogs/Blogs.php");

      dispatch({
        type: "TopTrendingContent",
        payload: result.latest_news.slice(0, 4),
      });
      dispatch({ type: "all_content", payload: result.latest_news.slice(4) });
      dispatch({
        type: "mostViewedContent",
        payload: result.most_viewed_news.slice(
          5,
          result.most_viewed_news.length
        ),
      });

      const getCategory = await _apiGetCalls("/Category/Category.php");
      dispatch({ type: "category", payload: getCategory });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        {/* adminroutes */}

        <Route
          path="/Admin/dashboard/edit/:id"
          element={
            <Suspense fallback={<Loading />}>
              <div className="App">
                <BlogEdit />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/Admin/dashboard/blogs/insert"
          element={
            <Suspense fallback={<Loading />}>
              <div className="App">
                <BlogInsertion />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/Admin/dashboard/blogs"
          element={
            <Suspense fallback={<Loading />}>
              <div className="App">
                <Blogs />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/Admin/dashboard/"
          element={
            <Suspense fallback={<Loading />}>
              <div className="App">
                <Dashboard />
              </div>
            </Suspense>
          }
        />

        <Route
          path="/Admin"
          element={
            <Suspense fallback={<Loading />}>
              <div className="App">
                <AdminHome />
              </div>
            </Suspense>
          }
        />
        <Route
          path="/Aboutus"
          element={
            <Suspense fallback={<Loading />}>
              <Aboutus />
            </Suspense>
          }
        />
        <Route
          path="/Aboutus"
          element={
            <Suspense fallback={<Loading />}>
              <Aboutus />
            </Suspense>
          }
        />
        <Route
          path="/privacypolicy"
          element={
            <Suspense fallback={<Loading />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />
        <Route
          path="/news/:category"
          element={
            <Suspense fallback={<Loading />}>
              <NewsByCate />
            </Suspense>
          }
        />
        <Route
          path="/news/article/:category/:newstitle"
          element={
            <Suspense fallback={<Loading />}>
              <NewsDetail />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
