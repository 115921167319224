import api from "./Api";

export const _apiGetCalls = (url, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get(url, {
        headers: { Authorization: token },
      });
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const _apiPostCalls = (url, data, token) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post(url, data);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};
