import React from "react";
import Logo from "../../assets/image/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function Loading() {
  const loading = useSelector((state) => state.loading);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    if (loading) {
      setLoadingPercentage(100);
      return;
    }
    setLoadingPercentage(60);
  }, [loading]);
  return (
    <div className="w-full h-screen bg-black flex items-center justify-center flex-col">
      <div>
        <img src={Logo} alt="app_logo" className="w-52" />
      </div>
      <div className="w-72 pt-6">
        <div className="w-full bg-slate-900 h-1 rounded-lg">
          <div
            style={{
              transition: `width 1s ease-in`,
              width: `${loadingPercentage}%`,
            }}
            className="w-2/3 h-full bg-white rounded-lg"
          ></div>
        </div>
        <div
          
          className="text-center my-3 tracking-wide uppercase letter text-xs text-slate-300"
        >
          Loading...
        </div>
      </div>
    </div>
  );
}

export default Loading;
