export const initialState = {
  mostViewedContent: [],
  cricketcontent: [],
  TopTrending: [],
  allcontent: [],
  loading: true,
  category: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "mostViewedContent":
      return { ...state, mostViewedContent: action.payload };
    case "TopTrendingContent":
      return { ...state, TopTrending: action.payload };
    case "cricket_content":
      return { ...state, cricketcontent: action.payload };
    case "all_content":
      return { ...state, allcontent: [...state.allcontent, ...action.payload] };
    case "loading":
      return { ...state, loading: action.payload };
    case "category":
      return { ...state, category: action.payload };
    default:
      return state;
  }
};
